import { Controller } from "@hotwired/stimulus";
import AirDatepicker from "air-datepicker";

const hide = (el) => {
  el.classList.add("d-none");
};
const show = (el) => {
  el.classList.remove("d-none");
};

const stringToDate = (str) => {
  const split = str.split(".").map((t) => parseInt(t));
  return new Date(split[2], split[1] - 1, split[0]);
};

function getDifferenceInDays(date1, date2) {
  const diffTime = date2 - date1;
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays;
}

function getPreviousMonday(date) {
  const dayOfWeek = date.getDay();
  const difference = dayOfWeek === 0 ? 6 : dayOfWeek - 1;
  const previousMonday = new Date(date);
  previousMonday.setDate(date.getDate() - difference);

  return previousMonday;
}

export default class extends Controller {
  static targets = ["calendar", "dates"];
  static values = {
    dates: String
  };

  calendarTargetConnected(target) {
    this.dates = this.dates || JSON.parse(this.datesValue).map((d) => stringToDate(d));
    this.updateCalendar();
  }

  updateCalendar() {
    if (this.calendar) {
      this.calendar.destroy();
    }
    this.calendar = new AirDatepicker(this.calendarTarget, {
      inline: true,
      // startDate: this.start,
      firstDay: 1,
      weekends: [0],
      fixedHeight: true,
      multipleDates: true,
      navTitles: { days: "MMMM" },
      moveToOtherMonthsOnSelect: false,
      minDate: new Date(),
      // maxDate: this.finish,
      selectedDates: this.dates,
      altField: this.datesTarget,
      altFieldDateFormat: "dd.MM.yyyy",
      onRenderCell: ({ date, cellType, datepicker }) => {
      },
      onSelect: ({ date, formattedDate, datepicker }) => {
        this.datesTarget.value = JSON.stringify(formattedDate);
      },
    });
    // this.datesTarget.value = JSON.stringify(this.dates.map((d) => dateToString(d)));
    this.calendar.setViewDate(this.dates[this.dates.length - 1]);
  }
}
