import { Controller } from "@hotwired/stimulus";
import { post, patch } from "@rails/request.js";

export default class extends Controller {
  isDispatch = false;

  connect() {
    this.element.addEventListener("submit", (event) => this.submitForm(event));
  }

  async submitForm(event) {
    event.preventDefault();
    if (!this.isDispatch) {
      this.start(event);
    }
  }

  async start(event) {
    this.isDispatch = true;
    document.querySelector("#page-loading").classList.remove('d-none')
    const method = event.target.method.toLowerCase();
    const url = event.target.action;
    const ajax = method === "post" ? post : patch;
    const response = await ajax(url, {
      headers: {
        contentType: "multipart/form-data;",
      },
      body: new FormData(event.target),
    });
    document.querySelector("#page-loading").classList.add('d-none')
    this.isDispatch = false;
    this.processingResponse(response);
  }

  async processingResponse(response) {
    const status = response.response.status;
    if (status === 422 || status === 200) {
      const result = await response.response.json();
      if (status === 422) {
        this.updateErrors(result?.full_errors);
      }
      if (status === 200) {
        this.dispatch("done")
        this.dispatch("hide", {});
      }
      if (result.notice) window.addToasts("notice", result.notice);
      if (result.alert) window.addToasts("alert", result.alert);
    } else {
      window.addToasts("alert", `ошибка: ${status}`);
    }
  }

  updateErrors(full_errors) {
    if(!full_errors) {
      return;
    }
    const errors = this.element.querySelector(".form_errors");
    errors.innerHTML = full_errors.map(t => getHTMLAlert(t)).join(" ");
  }
}

const getHTMLAlert = (t) => {
  return `<div class="alert alert-primary alert-dismissible fade show" role="alert">${t}<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></div>`
}