import { get, post, patch, destroy } from "@rails/request.js";
import { addToasts } from "../base";

const requestMethods = { get, post, patch, delete: destroy };

export const followLink = async ({ url, method, target, parentNode, controller }) => {
  const response = await (requestMethods[method] || get)(url);
  const status = response.response.status;

  if (status !== 200 && status !== 422) {
    return addToasts("alert", `ошибка: ${status}`);
  }
  const result = await response.response.json();
  if (result.modal) {
    let recordsId = parentNode?.closest("[data-records-ids]");
    window.appendAndShowFormModal(result.modal, recordsId);
  }
  if (result.deleted) {
    controller?.dispatch("done");
    controller?.dispatch("hide");
  }
  if (result.html) {
    const el = document.querySelector(target);
    if (el) {
      el.innerHTML = result.html;
    } else {
      return addToasts("alert", `target element not found`);
    }
  }
  if (result.url) {
    window.location = result.url;
  }
  if (result.notice) addToasts("notice", result.notice);
  if (result.alert) addToasts("alert", result.alert);
};
