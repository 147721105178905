require.context('../images', true);
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as bootstrap from "bootstrap"
import { Application } from "@hotwired/stimulus";
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers";
import "../javascripts/application";

export function definitionsFromContext2(context) {
  return context.keys()
      .map((key) => definitionForModuleWithContextAndKey(context, key))
      .filter((value) => value);
}
function definitionForModuleWithContextAndKey(context, key) {
  const identifier = identifierForContextKey(key);
  if (identifier) {
      return definitionForModuleAndIdentifier(context(key), identifier);
  }
}
function definitionForModuleAndIdentifier(module, identifier) {
  const controllerConstructor = module.default;
  if (typeof controllerConstructor == "function") {
      return { identifier, controllerConstructor };
  }
}
function identifierForContextKey(key) {
  const logicalName = (key.match(/^(?:\.\/)?(.+)(?:[/_-]controller\..+?)$/) || [])[1];
  if (logicalName) {
      return `${logicalName.replace(/_/g, "-").replace(/\//g, "--")}`;
  }
}

const application = Application.start();
const stimulus = require.context("../javascripts/controllers", true, /\.js$/);
const simpleStimulus = require.context("../javascripts/simple_controllers", true, /\.js$/);
const components = require.context("../../components", true, /\.js$/);

const context1 = definitionsFromContext(stimulus);
const context2 = definitionsFromContext(simpleStimulus);
const context3 = definitionsFromContext2(components);
application.load(context1.concat(context2).concat(context3));

