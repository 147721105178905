window.appendAndShowFormModal = (content, recordsId) => {
  const str = `<div class="modal-dialog modal-xl">
  <div class="modal-content">
    <div class="modal-header bg-info-subtle" data-modal-target="header">
      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
    </div>
    <div class="modal-body" data-modal-target="body">
      ${content}
    </div>
  </div>
</div>
`;
  const modalEl = document.createElement("div");
  modalEl.classList.add("modal");
  modalEl.classList.add("fade");
  modalEl.tabIndex = "-1";
  modalEl.dataset.controller = "modal";
  if (content.indexOf('class="modal-dialog') > -1) {
    modalEl.innerHTML = content;
    // modalEl.dataset.controller = `${modalEl.dataset.controller} lessonform`
  } else {
    modalEl.innerHTML = str;
  }
  if (recordsId) {
    modalEl.dataset.recordsId = recordsId;
  }
  document.body.appendChild(modalEl);
};

export const addToasts = (type, toast) => {
  const container = document.querySelector(".toast-container");
  const el = `
    <div class="d-flex">
      <div class="toast-body">
      ${toast}
      </div>
      <button type="button" class="btn-close me-2 m-auto btn-close-white" data-bs-dismiss="toast" aria-label="Close"></button>
    </div>
  `;
  const parent = document.createElement("div");
  parent.classList.add("toast");
  parent.classList.add("text-white");
  if (type === "alert") {
    parent.classList.add("bg-danger");
  } else {
    parent.classList.add("bg-success");
  }
  parent.dataset.controller = "toast";
  parent.innerHTML = el;
  container.appendChild(parent);
};
window.addToasts = addToasts;
