import { Controller } from "@hotwired/stimulus";
import { Modal as BootstrapModal } from "bootstrap";

// const newRecordUrl = (url) => {
//   const lastSymbol = url.pathname.slice(-1);
//   return isNaN(Number(lastSymbol));
// };

// const setSaveButtonProps = (target, url, id) => {
//   const newRecord = newRecordUrl(url);
//   target.innerHTML = newRecord ? "Создать" : "Сохранить";
//   target.setAttribute("form", id);
//   target.classList.remove("d-none");
// };

const prepairDataset = (exists, value) => {
  if (exists) {
    return `${exists} ${value}`;
  } else {
    return value;
  }
};

export default class extends Controller {
  static targets = ["header", "save", "body"];
  isDispatch = false;

  connect() {
    this.prepairForm();
    this.modal = new BootstrapModal(this.element, {
      focus: true,
      backdrop: "static",
    });
    this.modal.show();
    this.element.addEventListener("hidden.bs.modal", (event) => {
      if (event.target === this.element) {
        this.element.remove();
      }
    });
  }

  hide() {
    this.modal.hide();
  }

  prepairForm() {
    const forms = this.element.querySelectorAll("form");
    if (forms.length === 0) {
      return;
    }
    for(const form of forms) {
      // setSaveButtonProps(this.saveTarget, new URL(form.action), form.id);
      form.dataset.controller = prepairDataset(form.dataset.controller, "form");
      form.dataset.action = prepairDataset(
        form.dataset.action,
        "form:hide->modal#hide link:hide@window->modal#hide",
      );
      const recordsId = this.element.dataset.recordsId;
      if (recordsId) {
        form.dataset.target = recordsId;
      }
    }
  }
}
