import DataTableInit from './datatables';

document.addEventListener('DOMContentLoaded', function () {
  eventsButtonSetActive();
})

function getStateParams() {
  const location = window.location.pathname;
  if (location === '/user/events/past') { return 'past'; }
  if (location === '/user/events/canceled') { return 'canceled'; }
  if (location === '/admin/events/past') { return 'past'; }
  if (location === '/admin/events/canceled') { return 'canceled'; }
  return 'default';
}

function eventsButtonSetActive() {
  const state = getStateParams()
  const btn = document.querySelector(`[data-btn-state='${state}']`)
  btn?.classList.add('active')
}

/// ajax datatable
document.addEventListener('DOMContentLoaded', function () {
  const params = getStateParams();
  let customCol = params === 'default' ? 'actions' : null;

  DataTableInit('.admin-ajax-datatable', ['user', customCol])
  DataTableInit('.ajax-datatable', [customCol])
});
