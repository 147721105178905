import { Controller } from "@hotwired/stimulus";
import { followLink } from "../components/link";

export default class extends Controller {
  connect() {
    this.element.addEventListener("click", (event) => {
      event.preventDefault();
      this.prepairClick();
    });
  }

  prepairClick() {
    const question = this.element.dataset.confirm;
    if (question && !confirm(question)) {
      return;
    }
    followLink({
      url: this.element.href,
      method: this.element.dataset.method,
      target: this.element.dataset.target,
      controller: this,
      parentNode: this.element.parentNode
    })
  }
}
