import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["second", "secondBtn", "base"];
  connect() {
    document.addEventListener("click", (event) => {
      if (this.baseTarget.contains(event.target)) {
        return;
      }

      // This is a click outside.
      this.secondBtnTarget?.classList?.remove("open");
      this.secondTarget.classList.add("d-none");
    });
  }

  openSecond(e) {
    e.preventDefault();
    this.secondBtnTarget?.classList?.toggle("open");
    this.secondTarget.classList.toggle("d-none");
  }
}
