import { Controller } from "@hotwired/stimulus";
import { followLink } from "../../assets/javascripts/components/link";

export default class extends Controller {
  async update(details) {
    details.preventDefault();
    details.stopPropagation();
    followLink({
      url: this.element.dataset.url,
      target: this.element.dataset.target
    })
  }
}
